import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  rootHeader: {},
  rootResults: {},
  queryField: {
    width: 500,
  },
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  action: {
    marginBottom: theme.spacing(1),
    '& + &': {
      marginLeft: theme.spacing(1),
    },
  },
  actionIcon: {
    marginRight: theme.spacing(1),
  },
}));

export default useStyles;
