import React, { useState, useRef } from 'react';
import Modal from '@mui/material/Modal';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import { Typography, Button, CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
import axios from '../../../utils/axios';
import useStyles from './styles';

const UploadProductModal = ({ open, handleClose }) => {
  const classes = useStyles();
  const [selectedFileName, setSelectedFileName] = useState('');
  const [fileKey, setFileKey] = useState(0);
  const [loading, setLoading] = useState(false);
  const hiddenFileInput = useRef(null);
  const { enqueueSnackbar } = useSnackbar();

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleUpload = (event) => {
    const fileUploaded = event.target.files[0];
    if (!fileUploaded) {
      enqueueSnackbar('Por favor, seleccione un archivo', { variant: 'error' });
      return;
    }
    const { name } = fileUploaded;

    if (!name.endsWith('.xlsx') && !name.endsWith('.xls')) {
      enqueueSnackbar('El archivo debe ser .xlsx o .xls', {
        variant: 'error',
      });
      return;
    }

    setSelectedFileName(name);
  };

  const handleDeleteFile = () => {
    setSelectedFileName('');
    setFileKey((prevKey) => prevKey + 1);
    hiddenFileInput.current.value = '';
  };

  const handleSaveChanges = async () => {
    if (!selectedFileName) {
      return;
    }

    const formData = new FormData();
    formData.append('file', hiddenFileInput.current.files[0]);

    setLoading(true);
    try {
      const { data } = await axios.post('/api/products/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      enqueueSnackbar(data.message, {
        variant: 'success',
      });
      handleClose();
    } catch (err) {
      const errorMessage = err.response?.data?.errorMessage || 'Error al subir el archivo';
      enqueueSnackbar(errorMessage, {
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal open={open} onClose={handleClose} className={classes.modalContainer}>
      <div className={classes.modalContent}>
        <div className={classes.modalTitleContainer}>
          <Typography className={classes.modalTitle}>
            Cargar Productos
          </Typography>
          <CloseIcon className={classes.closeIcon} onClick={handleClose} />
        </div>
        <hr className={classes.modalLine} />
        <div className={classes.modalButtonContainer}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClick}
            className={classes.uploadButton}
            disabled={loading}
          >
            Subir Archivo
          </Button>
          <input
            key={fileKey}
            type="file"
            onChange={handleUpload}
            ref={hiddenFileInput}
            style={{ display: 'none' }}
          />
        </div>
        {selectedFileName && (
          <Typography className={classes.selectedFileName}>
            {selectedFileName}
            <CloseIcon
              className={classes.deleteButton}
              onClick={handleDeleteFile}
            />
          </Typography>
        )}
        <div className={classes.saveChangesContainer}>
          <Button
            variant="contained"
            color="secondary"
            className={classes.saveChangesButton}
            onClick={handleSaveChanges}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Guardar Cambios'}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

UploadProductModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
};

export default UploadProductModal;
