import React, { useEffect, useState } from 'react';
import { Box, Container } from '@mui/material';
import { useSnackbar } from 'notistack';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import ProductStocks from './ProductStocks';
import Product from './Product';
import {
  getProductById,
  getProductStocks,
} from '../../../requests/api/product';
import handleApiResponse from '../../../utils/handleApiResponse';

import useStyles from './styles';

function ProductDetailView(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [product, setProduct] = useState({});
  const [sendQuery, setSendQuery] = useState(false);
  const [productStocks, setProductStocks] = useState([]);

  useEffect(() => {
    const getProducts = async () => {
      try {
        const response = await getProductStocks(props.match.params.id);
        setProductStocks(response.stocks);
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };
    getProducts();
  }, []);

  useEffect(() => {
    const getProduct = async () => {
      try {
        const response = await getProductById(props.match.params.id);
        setProduct(response.product);
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };

    getProduct();
  }, [props, sendQuery]);

  return (
    <Page className={classes.root} title="Detalle del producto">
      <Container maxWidth={false}>
        <Header />
        {productStocks && (
          <div>
            <Box mt={3}>
              <Product
                product={product}
                setSendQuery={setSendQuery}
              />
            </Box>
            <Box mt={3}>
              <ProductStocks
                productStocks={productStocks}
              />
            </Box>
          </div>
        )}
      </Container>
    </Page>
  );
}

ProductDetailView.propTypes = {
  match: PropTypes.any,
};

export default withRouter(ProductDetailView);
