/* eslint-disable no-use-before-define */
import React, { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  // ListSubheader,
  Typography,
} from '@mui/material';

import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import Logo from '../../Layout/Logo';
import NavItem from './NavItem';
import useStyles from './styles';

import { ADMIN, SELLER, SUPER_ADMIN } from '../../../constants/userRoles';

const adminNav = [
  {
    items: [
      {
        title: 'Usuarios',
        icon: PersonOutlineIcon,
        href: '/administracion/users',
      },
      {
        title: 'Clientes',
        icon: PeopleAltIcon,
        href: '/administracion/clientes',
      },
      {
        title: 'Pedidos',
        icon: ShoppingCartIcon,
        href: '/administracion/pedidos',
      },
      {
        title: 'Productos',
        icon: ShoppingBagIcon,
        href: '/administracion/productos',
      },
      {
        title: 'Vendedores',
        icon: AttachMoneyIcon,
        href: '/administracion/vendedores',
      },
    ],
  },
];

const sellerNav = [
  {
    items: [
      {
        title: 'Clientes',
        icon: PeopleAltIcon,
        href: '/vendedor/clientes',
      },
      {
        title: 'Pedidos',
        icon: ShoppingCartIcon,
        href: '/vendedor/pedidos',
      },
      {
        title: 'Dashboard',
        icon: AttachMoneyIcon,
        href: '/vendedor/dashboard',
      },
    ],
  },
];

function renderNavItems({ items, ...rest }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
        [],
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc, pathname, item, depth = 0,
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        info={item.info}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>,
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={key}
        info={item.info}
        title={item.title}
      />,
    );
  }

  return acc;
}

function NavBar({ openMobile, onMobileClose }) {
  const classes = useStyles();
  const location = useLocation();
  const account = useSelector((state) => state.account);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const renderNav = () => {
    let navConfig = [];

    if ([ADMIN, SUPER_ADMIN].includes(account.user.role)) {
      navConfig = adminNav;
    }
    if (account.user.role === SELLER) {
      navConfig = sellerNav;
    }
    return (
      <React.Fragment>
        {navConfig.map((config, index) => (
          <List key={index}>
            {renderNavItems({
              items: config.items,
              pathname: location.pathname,
            })}
          </List>
        ))}
      </React.Fragment>
    );
  };

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo className={classes.logo} />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box display="flex" justifyContent="center">
            <RouterLink to="/">
              <Avatar alt="User" className={classes.avatar} />
            </RouterLink>
          </Box>
          <Box mt={2} textAlign="center">
            <Link
              component={RouterLink}
              to="/"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {account.user && account.user.name}
            </Link>
            <Typography variant="body2" color="textSecondary">
              {account.user && account.user.email}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>{renderNav()}</Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
