import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, Button } from '@mui/material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { useHistory } from 'react-router';

import useStyles from './styles';

function Header({ className, ...rest }) {
  const classes = useStyles();
  const history = useHistory();

  const handleBack = () => {
    history.goBack();
  };

  return (
    <Grid
      container
      justifyContent="space-between"
      spacing={3}
      {...rest}
    >
      <Grid item>
        <div className={classes.headerTitleDiv}>
          <AttachMoneyIcon className={classes.shoppingBagIcon}/>
          <Typography
            className={classes.headerTitle}
          >
            Detalle del vendedor
          </Typography>
        </div>
      </Grid>
      <Grid item>
        <Button variant="contained" color="primary" onClick={handleBack}>
          Volver
        </Button>
      </Grid>
    </Grid>
  );
}

Header.propTypes = {
  className: PropTypes.string,
};

export default Header;
