import produce from 'immer';
import clientsFiltersActions from '../actions/clientsActions';

const initialState = {
  clientsFilters: {},
};

const clientsFiltersReducer = (state = initialState, action) => {
  switch (action.type) {
    case clientsFiltersActions.SET_CLIENTS_FILTERS: {
      const { clientsFilters } = action.payload;

      return produce(state, (draft) => {
        draft.clientsFilters = clientsFilters;
      });
    }

    default: {
      return state;
    }
  }
};

export default clientsFiltersReducer;
