import React, {
  useState,
  useEffect,
} from 'react';
import { withRouter } from 'react-router-dom';
import {
  Box,
  Container,
} from '@mui/material';
import PropTypes from 'prop-types';
import { enqueueSnackbar } from 'notistack';

import Page from '../../../../components/Layout/Page';
import useIsMountedRef from '../../../../hooks/useIsMountedRef';
import UserEditForm from './UserEditForm';
import Header from './Header';

import { getUserDetail } from '../../../../requests/api/users';

import useStyles from './styles';
import handleApiResponse from '../../../../utils/handleApiResponse';
import { getSellersPerPage } from '../../../../requests/api/seller';
import { SUPER_ADMIN } from '../../../../constants/userRoles';
import withAuth from '../UserAuthenticator';

function UserEditView(props) {
  const classes = useStyles();
  const isMountedRef = useIsMountedRef();
  const [user, setUser] = useState();
  const [sellers, setSellers] = useState([]);

  useEffect(() => {
    const getUser = async () => {
      const response = await getUserDetail(props.match.params.id);

      if (response.success && isMountedRef.current) {
        setUser(response.user);
      }
    };
    const getSellers = async () => {
      try {
        const response = await getSellersPerPage({
          page: 0,
          limit: 10000,
          search: '',
          area: '',
        });
        setSellers(response.sellers);
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };
    getSellers();
    getUser();
    // eslint-disable-next-line
  }, [props]);

  if (!user) {
    return null;
  }

  return (
    <Page
      className={classes.root}
      title="Ventas FH Engel | Editar Usuario"
    >
      <Container maxWidth="lg">
        <Header />
        <Box mt={3}>
          <UserEditForm sellers={sellers} user={user} />
        </Box>
      </Container>
    </Page>
  );
}

UserEditView.propTypes = {
  match: PropTypes.any,
};

export default withAuth(withRouter(UserEditView), SUPER_ADMIN);
