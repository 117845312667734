import axios from '../../utils/axios';

const getSellerDetailViewData = async (sellerId) => {
  const response = await axios.get(`/api/seller/detail-view/${sellerId}`);

  return response.data;
};

const getSellersPerPage = async ({
  page, limit, search, area,
}) => {
  const response = await axios.get(`/api/sellers?page=${page}&limit=${limit}&search=${search}&area=${area}`);

  return response.data;
};

export {
  getSellerDetailViewData,
  getSellersPerPage,
};
