import axios from '../../utils/axios';

const getClientDetailViewData = async (clientId) => {
  const response = await axios.get(`/api/client/detail-view/${clientId}`);

  return response.data;
};

const getClientsPerPage = async ({
  page,
  limit,
  search,
  status,
  paymentConditions,
  sellerId,
}) => {
  const response = await axios.get(
    `/api/clients?page=${page}&limit=${limit}&search=${search}&status=${status}&paymentConditions=${paymentConditions}&sellerId=${sellerId}`,
  );

  return response.data;
};

export { getClientDetailViewData, getClientsPerPage };
