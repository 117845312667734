import React, { useState, useEffect } from 'react';
import { Container } from '@mui/material';
import { withRouter } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import ProductEditForm from './ProductEditForm';
import handleApiResponse from '../../../utils/handleApiResponse';
import {
  getProductById,
} from '../../../requests/api/product';
import useStyles from './styles';

function ProductEditView(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [product, setProduct] = useState();

  useEffect(() => {
    const getProduct = async () => {
      try {
        const response = await getProductById(props.match.params.id);
        setProduct(response.product);
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };

    getProduct();
  }, [props]);

  if (!product) {
    return null;
  }

  return (
    <Page
      className={classes.root}
      title="Editar Producto"
    >
      <Container maxWidth={false}>
        <Header />
        <ProductEditForm
          product={product}
        />
      </Container>
    </Page>
  );
}

ProductEditView.propTypes = {
  match: PropTypes.any,
};

export default withRouter(ProductEditView);
