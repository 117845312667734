import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Grid,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import { formatDate } from '../../../utils/date';
import useStyles from './styles';

function ClientArticleGroupsWarning({ client }) {
  const classes = useStyles();
  const [clientArticleGroups, setClientArticleGroups] = useState([]);
  const [show, setShow] = useState(false);
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();
  const currentDay = currentDate.getDate();
  const monthsAgo4 = new Date(currentYear, currentMonth - 4, currentDay);

  useEffect(() => {
    if (Object.keys(client).length !== 0) {
      const filteredClientArticleGroups = client.clientArticleGroups.filter(
        (element) => new Date(element.lastBuy.date).getTime() < monthsAgo4.getTime(),
      );

      setClientArticleGroups(filteredClientArticleGroups || []);

      if (filteredClientArticleGroups.length > 0) {
        setShow(true);
      }
    }
  }, [client]);

  if (Object.keys(client).length === 0) {
    return null;
  }

  if (!show) {
    return null;
  }

  return (
    <div className={classes.warningDiv}>
      <Card>
        <PerfectScrollbar>
          <Grid
            container
            justifyContent="space-between"
            className={classes.cardHeaderDiv}
          >
            <Grid
              container
              alignItems="center"
              className={classes.cardTitleDiv}
            >
              <ErrorIcon className={classes.warningIcon} />
              <Typography className={classes.cardTitle}>
                Grupo Artículos sin compra reciente
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Table stickyHeader={true}>
                <TableHead>
                  <TableRow>
                    {/* <TableCell>Vendedor</TableCell> */}
                    <TableCell>Grupo Artículo</TableCell>
                    <TableCell>Fecha última compra</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {clientArticleGroups.map((clientArticleGroup) => (
                  // TODO: Add seller Name
                  <TableRow hover key={clientArticleGroup.articleGroup.id}>
                    {/* <TableCell>
                      <Typography color="inherit" variant="h6">
                        {clientArticleGroup.lastBuy.seller.name}
                      </Typography>
                    </TableCell> */}
                    <TableCell>
                      <Typography color="inherit" variant="h6">
                        {clientArticleGroup.articleGroup.name}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography color="inherit" variant="h6">
                        {formatDate(clientArticleGroup.lastBuy.date)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              </Table>
            </Grid>
          </Grid>
        </PerfectScrollbar>
      </Card>
    </div>
  );
}

ClientArticleGroupsWarning.propTypes = {
  className: PropTypes.string,
  client: PropTypes.object.isRequired,
};

ClientArticleGroupsWarning.defaultProps = {
  client: {},
};

export default ClientArticleGroupsWarning;
