import React, { useEffect, useState } from 'react';
import { Box, Container } from '@mui/material';
import { useSnackbar } from 'notistack';

import { useSelector } from 'react-redux';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import Results from './Results';

import { getProductsPerPage } from '../../../requests/api/product';
import handleApiResponse from '../../../utils/handleApiResponse';
import useStyles from './styles';

function ProductsListView() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [products, setProducts] = useState([]);
  const [totalProducts, setTotalProducts] = useState(0);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [filters, setFilters] = useState({
    search: '',
    active: [],
    stock: [],
  });
  const [sendQuery, setSendQuery] = useState(false);
  const productsRedux = useSelector((state) => state.products);
  const [reduxFilters, setReduxFilters] = useState(false);

  useEffect(() => {
    const setReduxInfo = async () => {
      if (Object.keys(productsRedux.productsFilters).length > 0) {
        setFilters(productsRedux.productsFilters);
        setSendQuery((prev) => !prev);
      }
      setReduxFilters(true);
    };
    setReduxInfo();
  }, []);

  useEffect(() => {
    const getProducts = async () => {
      const joinActiveFilter = filters.active.map((e) => e.value).join(',');
      const joinStockFilter = filters.stock.map((e) => e.value).join(',');
      try {
        const response = await getProductsPerPage({
          page,
          limit,
          search: filters.search,
          active: joinActiveFilter,
          stock: joinStockFilter,
        });
        setProducts(response.products);
        setTotalProducts(response.totalProducts);
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };
    if (reduxFilters) {
      getProducts();
    }
  }, [sendQuery, reduxFilters, page, limit]);

  return (
    <Page className={classes.root} title="Productos">
      <Container maxWidth={false}>
        <Header />
        {products && (
          <Box mt={3}>
            <Results
              products={products}
              page={page}
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
              filters={filters}
              setFilters={setFilters}
              setSendQuery={setSendQuery}
              totalProducts={totalProducts}
            />
          </Box>
        )}
      </Container>
    </Page>
  );
}

export default ProductsListView;
