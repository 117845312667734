import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';

import {
  Box,
  Button,
  Card,
  IconButton,
  InputAdornment,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  Menu,
  MenuItem,
  Grid,
  Tooltip,
  Switch,
  CircularProgress,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import {
  Edit as EditIcon,
  Search as SearchIcon,
} from 'react-feather';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ClearIcon from '@mui/icons-material/Clear';
import Autocomplete from '@mui/material/Autocomplete';
import axios from '../../../utils/axios';
import useStyles from './styles';
import {
  productActiveOptions,
  productStockOptions,
} from '../../../utils/options';
import { putProductById } from '../../../requests/api/product';
import UploadProductModal from './UploadProductModal';

function Results({
  className,
  products,
  page,
  setPage,
  limit,
  setLimit,
  filters,
  setFilters,
  setSendQuery,
  totalProducts,
  ...rest
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [currentProducts, setCurrentProducts] = useState(products);
  const [openFilter, setOpenFilter] = useState(null);
  const [filtersCounter, setFiltersCounter] = useState(0);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setCurrentProducts(products);
  }, [products]);

  useEffect(() => {
    let count = 0;
    count += filters.active.length;
    count += filters.stock.length;
    setFiltersCounter(count);
  }, [filters]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handleClick = (event) => {
    setOpenFilter(event.currentTarget);
  };
  const handleClose = () => {
    setOpenFilter(null);
  };

  const handleFilterChange = (value, name) => {
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const handleClearFilters = (event) => {
    event.stopPropagation();
    setFilters({
      search: '',
      active: [],
      stock: [],
    });
    setSendQuery((prev) => !prev);
  };

  const handleEditProduct = async (product) => {
    const data = {
      sapId: product.sap_id,
      active: !product.active,
    };

    const response = await putProductById(data);
    if (response.success) {
      enqueueSnackbar('Producto editado correctamente', {
        variant: 'success',
      });
      setSendQuery((prev) => !prev);
    } else {
      enqueueSnackbar(response.data.errorMessage, {
        variant: 'warning',
      });
    }
  };

  const handleOpenUploadModal = () => {
    setOpenUploadModal(true);
  };

  const handleCloseUploadModal = () => {
    setOpenUploadModal(false);
  };

  const handleDownload = async () => {
    setLoading(true);
    try {
      const response = await axios.get('/api/products/download', {
        responseType: 'arraybuffer', // Para manejar archivos binarios
      });

      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'productos.xlsx';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      enqueueSnackbar('Error al descargar el archivo', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <Grid container alignItems="center" justifyContent="space-between" p={2} spacing={1}>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          spacing={1}
          className={classes.searchGrid}
        >
          <Grid item>
            <TextField
              className={classes.queryField}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              onChange={(e) => handleFilterChange(e.target.value, 'search')}
              placeholder="Código o Nombre"
              value={filters.search}
              variant="outlined"
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={handleClick}
              className={classes.filterButton}
              endIcon={
                <ClearIcon
                  onClick={(event) => handleClearFilters(event)}
                  edge="end"
                  color="inherit"
                  className={classes.clearIcon}
                />
              }
            >
              <div className={classes.filterIcon}>
                <FilterAltIcon />
                Filtros {`(${filtersCounter})`}
              </div>
            </Button>
            <Menu
              anchorEl={openFilter}
              open={Boolean(openFilter)}
              onClose={handleClose}
            >
              <MenuItem>
                <Autocomplete
                  className={classes.filterAutocomplete}
                  multiple
                  options={productActiveOptions}
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, value) => option.value === value.value
                  }
                  value={filters.active}
                  onChange={(event, value) => handleFilterChange(value, 'active')
                  }
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" placeholder="Estado" />
                  )}
                />
              </MenuItem>
              <MenuItem>
                <Autocomplete
                  className={classes.filterAutocomplete}
                  multiple
                  options={productStockOptions}
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, value) => option.value === value.value
                  }
                  value={filters.stock}
                  onChange={(event, value) => handleFilterChange(value, 'stock')
                  }
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" placeholder="Stock" />
                  )}
                />
              </MenuItem>
            </Menu>
          </Grid>
          <Grid item>
            <Button
              color="secondary"
              variant="contained"
              className={classes.searchButton}
              onClick={() => setSendQuery((prev) => !prev)}
            >
              Buscar
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={2} justifyContent="flex-start" style={{ marginTop: '16px' }}>
          <Grid item>
            <Button
              color="secondary"
              variant="contained"
              onClick={handleOpenUploadModal}
            >
              Cargar Productos
            </Button>
            <UploadProductModal
              open={openUploadModal}
              handleClose={handleCloseUploadModal}
            />
          </Grid>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              onClick={handleDownload}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : 'Descargar Productos'}
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <PerfectScrollbar>
        <Box minWidth={700}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align='center'>Código</TableCell>
                <TableCell align='center'>Nombre</TableCell>
                <TableCell align='center'>Stock principal</TableCell>
                <TableCell align='center'>Activo</TableCell>
                <TableCell align="right">Ver más</TableCell>
                <TableCell align="right">Editar</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentProducts.map((product) => (
                <TableRow hover key={product.id}>
                  <TableCell align='center'>
                    <Typography color="inherit" variant="h6">
                      {product.sap_id}
                    </Typography>
                  </TableCell>
                  <TableCell align='center'>
                    <Typography color="inherit" variant="h6">
                      {product.description}
                    </Typography>
                  </TableCell>
                  <TableCell align='center'>
                    <Typography color="inherit" variant="h6">
                      {product.totalFreeStock}
                    </Typography>
                  </TableCell>
                  <TableCell align='center'>
                    <Tooltip title={product.active ? 'Desactivar producto' : 'Activar producto'}>
                      <Switch
                        className={classes.switch}
                        checked={product.active}
                        onChange={() => handleEditProduct(product)}
                      />
                    </Tooltip>
                  </TableCell>
                  <TableCell align="right">
                    <IconButton
                      component={RouterLink}
                      to={`/administracion/productos/${product.id}/detalle`}
                      size="large"
                    >
                      <SvgIcon fontSize="small">
                        <MoreHorizIcon />
                      </SvgIcon>
                    </IconButton>
                  </TableCell>
                  <TableCell align="right">
                    <IconButton
                      component={RouterLink}
                      to={`/administracion/productos/${product.id}/editar`}
                      size="large"
                    >
                      <SvgIcon fontSize="small">
                        <EditIcon />
                      </SvgIcon>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={totalProducts}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
        labelRowsPerPage="Filas por página"
      />
    </Card>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  limit: PropTypes.number.isRequired,
  setLimit: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  products: PropTypes.array,
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
  setSendQuery: PropTypes.func.isRequired,
  totalProducts: PropTypes.number.isRequired,
};

Results.defaultProps = {
  products: [],
};

export default Results;
