import React from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';

import {
  Card,
  Grid,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TableBody,
} from '@mui/material';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import pesoChileno from '../../../utils/moneyFormat';
import useStyles from './styles';

function TopClients({ className, seller, ...rest }) {
  const classes = useStyles();

  if (Object.keys(seller).length === 0) {
    return null;
  }

  return (
    <Card className={classes.card} {...rest}>
      <div className={classes.cardTitleDiv}>
        <PeopleOutlineIcon className={classes.shoppingBagIcon} />
        <Typography className={classes.cardTitle}>Clientes</Typography>
      </div>
      <PerfectScrollbar>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} className={classes.scrollableTable}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Cliente</TableCell>
                  <TableCell>Venta</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {seller.top5Clients.map((e) => {
                  const { client, totalSell } = e;
                  return (
                    <TableRow hover key={client.id}>
                      <TableCell>
                        <Typography color="inherit" variant="h6">
                          {client.name}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography color="inherit" variant="h6">
                          {pesoChileno(totalSell)}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </PerfectScrollbar>
    </Card>
  );
}

TopClients.propTypes = {
  className: PropTypes.string,
  seller: PropTypes.object.isRequired,
};

TopClients.defaultProps = {
  seller: {},
};

export default TopClients;
