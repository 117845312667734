import axios from '../../utils/axios';

const postLogin = async ({
  email, password,
}) => {
  const response = await axios.post('/api/auth/login', {
    email,
    password,
  });

  return response.data;
};

const postRegister = async ({
  name, email, role, sellerId,
}) => {
  const response = await axios.post('/api/auth/register', {
    name, email, role, sellerId,
  });

  return response.data;
};

// RECOVER PASSWORD
const postRecoverPassword = async (email) => {
  const response = await axios.post('/api/auth/recover-password', {
    email,
  });

  return response.data;
};

// UPDATE PASSWORD
const updatePassword = async ({ token, password, confirmPassword }) => {
  const response = await axios.post('/api/auth/reset-password', {
    token,
    password,
    confirmPassword,
  });

  return response.data;
};

// USER VERIFICATION
const userVerification = async ({ token, password, confirmPassword }) => {
  const response = await axios.post('/api/auth/user-verification', {
    token,
    password,
    confirmPassword,
  });

  return response.data;
};

export {
  postLogin,
  postRegister,
  postRecoverPassword,
  updatePassword,
  userVerification,
};
