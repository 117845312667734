import React, { useEffect, useState } from 'react';
import { Container } from '@mui/material';
import { useSnackbar } from 'notistack';
import { withRouter } from 'react-router-dom';
import TimelineIcon from '@mui/icons-material/Timeline';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import Client from './Client';
import {
  getClientDetailViewData,
} from '../../../requests/api/client';
import handleApiResponse from '../../../utils/handleApiResponse';
import ChartButtons from './ChartButtons';
import useStyles from './styles';
import ClientDirections from './ClientDirections';
import LastFiveOrders from './LastFiveOrders';
import DataChart from './DataChart';
import ClientArticleGroupsWarning from './ClientArticleGroupsWarning';
import { SELLER } from '../../../constants/userRoles';

function ProductDetailView(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const account = useSelector((state) => state.account);
  const history = useHistory();

  const [client, setClient] = useState({});
  const [chart, setChart] = useState(0);

  useEffect(() => {
    const getClient = async () => {
      try {
        const response = await getClientDetailViewData(props.match.params.id);
        setClient(response.client);
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);

        if (account.user.role === SELLER) {
          history.push('/vendedor/clientes');
        } else {
          history.push('/administracion/clientes');
        }
      }
    };

    getClient();
    // eslint-disable-next-line
  }, [props]);

  return (
    <Page className={classes.root} title="Detalle del cliente">
      <Container maxWidth={false}>
        <Header />
        <div>
          <Client client={client} />
          <div className={classes.firstSquare}>
            <div className={classes.leftColumn}>
              <ClientDirections client={client} />
            </div>
            <div className={classes.rightColumn}>
              <LastFiveOrders client={client} />
            </div>
          </div>
          <ClientArticleGroupsWarning client={client} />

          <div className={classes.secondSquare}>
            <div className={classes.dataChartTitle}>
              <TimelineIcon className={classes.timeLineIcon} />
              Data
            </div>

            <div className={classes.chartButtonsDiv}>
              <ChartButtons chart={chart} setChart={setChart} />
            </div>

            <DataChart client={client} chart={chart} />
          </div>
        </div>
      </Container>
    </Page>
  );
}

ProductDetailView.propTypes = {
  match: PropTypes.any,
};

export default withRouter(ProductDetailView);
