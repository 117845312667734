/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import {
  Button,
  Card,
  Grid,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TableBody,
} from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { formatDate } from '../../../utils/date';
import ordersFiltersActions from '../../../actions/ordersActions';
import useStyles from './styles';

function LastFiveOrders({ client }) {
  const classes = useStyles();
  const [lastFiveOrders, setLastFiveOrders] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    const lastFive = client.orders?.slice(-5);
    setLastFiveOrders(lastFive || []);
  }, [client]);

  const handleSeeMoreClick = () => {
    dispatch(ordersFiltersActions.setOrdersFilters({
      search: client.name,
    }));

    history.push('/administracion/pedidos');
  };

  if (Object.keys(client).length === 0) {
    return null;
  }

  return (
    <Card className={classes.card}>
      <Grid
        container
        className={classes.cardHeaderDiv}
        justify="space-between"
        alignItems="center"
      >
        <Grid item>
          <Grid container alignItems="center" className={classes.cardTitleDiv}>
            <ShoppingCartIcon className={classes.shoppingBagIcon} />
            <Typography className={classes.cardTitle}>Pedidos</Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Button
            color="secondary"
            variant="contained"
            className={classes.searchButton}
            onClick={() => handleSeeMoreClick()}
          >
            Ver más
          </Button>
        </Grid>
      </Grid>
      <PerfectScrollbar>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} className={classes.scrollableTable}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Dirección</TableCell>
                  <TableCell>Estado</TableCell>
                  <TableCell>Fecha Entrega</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {lastFiveOrders.map((order) => {
                  const { clientDirections } = client;
                  const orderClientDirection = clientDirections.find((e) => e.id === order.clientDirectionId);
                  return (
                  <TableRow hover key={order.id}>
                    <TableCell>
                      <Typography color="inherit" variant="h6">
                        {`${orderClientDirection?.address || ''}, ${orderClientDirection?.commune.name || ''}`}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography color="inherit" variant="h6">
                        {order.status}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography color="inherit" variant="h6">
                        {formatDate(order.date)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </PerfectScrollbar>
    </Card>
  );
}

LastFiveOrders.propTypes = {
  className: PropTypes.string,
  client: PropTypes.object.isRequired,
};

LastFiveOrders.defaultProps = {
  client: {},
};

export default LastFiveOrders;
