import axios from '../../utils/axios';

const getUserDetail = async (id) => {
  const response = await axios.get(`/api/users/${id}`);

  return response.data;
};

const editUserData = async (id, newData) => {
  const response = await axios.put(`/api/users/${id}`, newData);

  return response.data;
};

const deleteUsers = async (userIds) => {
  const response = await axios.delete(`/api/users/${userIds}`);

  return response.data;
};

const getUserById = async (userId) => {
  const response = await axios.get(`/api/users/${userId}`);

  return response.data;
};

const getUsersPerPage = async (page, limit, searchValue, sort) => {
  const response = await axios.get(`/api/users?page=${page}&limit=${limit}&searchValue=${searchValue}&sort=${sort}`);

  return response.data;
};

export {
  getUserDetail,
  editUserData,
  deleteUsers,
  getUserById,
  getUsersPerPage,
};
