import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Typography } from '@mui/material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import useStyles from './styles';

function Header({ className, ...rest }) {
  const classes = useStyles();

  return (
    <Grid
      container
      justifyContent="space-between"
      spacing={3}
      {...rest}
    >
      <Grid item>
        <Box display="flex" alignItems="center">
        <AttachMoneyIcon className={classes.shoppingBagIcon}/>
          <Typography
            className={classes.headerTitle}
          >
            Vendedores
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
}

Header.propTypes = {
  className: PropTypes.string,
};

export default Header;
