import React, { useEffect, useState } from 'react';
import { Box, Container } from '@mui/material';
import { useSnackbar } from 'notistack';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import Order from './Order';
import {
  getOrderById,
} from '../../../requests/api/order';
import handleApiResponse from '../../../utils/handleApiResponse';
import { SELLER } from '../../../constants/userRoles';

import useStyles from './styles';

function OrderDetailView(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const account = useSelector((state) => state.account);

  const [order, setOrder] = useState({});
  const history = useHistory();

  useEffect(() => {
    const getOrder = async () => {
      try {
        const response = await getOrderById(props.match.params.id);
        setOrder(response.order);
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);

        if (account.user.role === SELLER) {
          history.push('/vendedor/pedidos');
        } else {
          history.push('/administracion/pedidos');
        }
      }
    };

    getOrder();
    // eslint-disable-next-line
  }, [props]);

  return (
    <Page className={classes.root} title="Detalle del pedido">
      <Container maxWidth={false}>
        <Header />
          <div>
            <Box mt={3}>
              <Order
                order={order}
              />
            </Box>
          </div>
      </Container>
    </Page>
  );
}

OrderDetailView.propTypes = {
  match: PropTypes.any,
};

export default withRouter(OrderDetailView);
