import produce from 'immer';
import productsFiltersActions from '../actions/productsActions';

const initialState = {
  productsFilters: {},
};

const productsFiltersReducer = (state = initialState, action) => {
  switch (action.type) {
    case productsFiltersActions.SET_PRODUCTS_FILTERS: {
      const { productsFilters } = action.payload;

      return produce(state, (draft) => {
        draft.productsFilters = productsFilters;
      });
    }

    default: {
      return state;
    }
  }
};

export default productsFiltersReducer;
