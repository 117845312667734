import moment from 'moment';

const months = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];

const formatDateTime = (time) => {
  const opciones = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  };
  const formatedDate = new Date(time).toLocaleDateString('es', opciones);
  return formatedDate;
};

const formatDate = (date) => {
  const opciones = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  };
  const formatedDate = new Date(date).toLocaleDateString('es-CL', opciones);
  return formatedDate;
};

const getFirstDayOfCurrentMonth = (date) => {
  const month = date.getMonth();
  const year = date.getFullYear();
  const firstDay = new Date(year, month, 1);
  return firstDay.toISOString();
};

const getLastMonth = (date) => {
  const dateLastMonth = new Date(date);
  dateLastMonth.setMonth(dateLastMonth.getMonth() - 1);
  return dateLastMonth.toISOString();
};

const getNextMonth = (date) => {
  const dateNextMonth = new Date(date);
  dateNextMonth.setMonth(dateNextMonth.getMonth() + 1);
  return dateNextMonth.toISOString();
};

const formatMonthAndYearMoment = (date) => {
  const formatedDate = moment(date).format('MM-YYYY');
  return formatedDate;
};

export {
  months,
  formatDateTime,
  formatDate,
  getFirstDayOfCurrentMonth,
  getLastMonth,
  getNextMonth,
  formatMonthAndYearMoment,
};
