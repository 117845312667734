import produce from 'immer';
import sellersFiltersActions from '../actions/sellersActions';

const initialState = {
  sellersFilters: {},
};

const sellersFiltersReducer = (state = initialState, action) => {
  switch (action.type) {
    case sellersFiltersActions.SET_SELLERS_FILTERS: {
      const { sellersFilters } = action.payload;

      return produce(state, (draft) => {
        draft.sellersFilters = sellersFilters;
      });
    }

    default: {
      return state;
    }
  }
};

export default sellersFiltersReducer;
