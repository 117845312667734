import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  // InputAdornment,
  // SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  // TextField,
  Typography,
} from '@mui/material';
// import { Search as SearchIcon } from 'react-feather';

import useStyles from './styles';

function Results({
  className,
  productStocks,
  ...rest
}) {
  const classes = useStyles();
  const [currentProductStocks, setCurrentProductStocks] = useState(productStocks);

  useEffect(() => {
    setCurrentProductStocks(productStocks);
  }, [productStocks]);

  // const handleQueryChange = (event) => {
  //   event.persist();
  //   setQuery(event.target.value);
  // };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <Box
        p={2}
        minHeight={56}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        {/* <TextField
          className={classes.queryField}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SvgIcon fontSize="small" color="action">
                  <SearchIcon />
                </SvgIcon>
              </InputAdornment>
            ),
          }}
          onChange={handleQueryChange}
          placeholder="Buscar"
          value={query}
          variant="outlined"
        /> */}
      </Box>

      <PerfectScrollbar>
        <Box minWidth={700}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">Stock</TableCell>
                <TableCell align="center">Bodega</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentProductStocks.map((stock) => (
                <TableRow hover key={stock.id}>
                  <TableCell align="center">
                    <Typography color="inherit" variant="h6">
                      {stock.free_stock}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography color="inherit" variant="h6">
                      {stock.storage.name}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  // query: PropTypes.string.isRequired,
  // setQuery: PropTypes.func.isRequired,
  productStocks: PropTypes.array,
};

Results.defaultProps = {
  productStocks: [],
};

export default Results;
