import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TableBody,
} from '@mui/material';
import { formatDate } from '../../../utils/date';
// import pesoChileno from '../../../utils/moneyFormat';
import useStyles from './styles';

function Order({ className, order, ...rest }) {
  const classes = useStyles();

  if (Object.keys(order).length === 0) {
    return null;
  }

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={700}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Número de factura</TableCell>
                <TableCell>Total Bruto</TableCell>
                <TableCell>Total neto</TableCell>
                <TableCell>Estado</TableCell>
                <TableCell>Fecha creación</TableCell>
                <TableCell>Fecha entrega</TableCell>
                <TableCell>Cliente</TableCell>
                <TableCell>Dirección cliente</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow hover key={order.id}>
                <TableCell>
                  <Typography color="inherit" variant="h6">
                    {order.sapId}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="inherit" variant="h6">
                    {''}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="inherit" variant="h6">
                    {''}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="inherit" variant="h6">
                    {order.status}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="inherit" variant="h6">
                    {formatDate(order.createdAt)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="inherit" variant="h6">
                    {formatDate(order.date)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="inherit" variant="h6">
                    {order.client.name}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="inherit" variant="h6">
                    {order.clientDirection.address}
                    {', '}
                    {order.clientDirection.commune.name}
                    {', '}
                    {order.clientDirection.city.name}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
}

Order.propTypes = {
  className: PropTypes.string,
  order: PropTypes.object.isRequired,
};

Order.defaultProps = {
  order: {},
};

export default Order;
