import React from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Grid,
  Card,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  Typography,
} from '@mui/material';

function Seller({ seller }) {
  // const classes = useStyles();

  if (Object.keys(seller).length === 0) {
    return null;
  }

  return (
    <Card>
      <PerfectScrollbar>
        <Grid container justifyContent="space-between" >
          <Grid item xs={12}>
            <Table stickyHeader={true}>
              <TableHead>
                <TableRow>
                  <TableCell>Código</TableCell>
                  <TableCell>Nombre</TableCell>
                  <TableCell>Correo</TableCell>
                  <TableCell>Estado</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow hover key={seller.id}>
                  <TableCell>
                    <Typography color="inherit" variant="h6">
                      {seller.sapId || ''}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color="inherit" variant="h6">
                      {seller.name || seller.user.name}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color="inherit" variant="h6">
                      {seller.user?.email || ''}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color="inherit" variant="h6">
                      {seller.active ? 'Activo' : 'Inactivo'}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </PerfectScrollbar>
    </Card>
  );
}

Seller.propTypes = {
  seller: PropTypes.object.isRequired,
};

Seller.defaultProps = {
  seller: {},
};

export default Seller;
