import React, { useEffect, useState } from 'react';
import { Container } from '@mui/material';
import { useSnackbar } from 'notistack';
import Page from '../../../../components/Layout/Page';
import Header from './Header';
import UserCreateForm from './UserCreateForm';
import { getSellersPerPage } from '../../../../requests/api/seller';
import handleApiResponse from '../../../../utils/handleApiResponse';

import useStyles from './styles';
import withAuth from '../UserAuthenticator';
import { SUPER_ADMIN } from '../../../../constants/userRoles';

function CustomerCreateView() {
  const classes = useStyles();
  const [sellers, setSellers] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const getSellers = async () => {
      try {
        const response = await getSellersPerPage({
          page: 0,
          limit: 10000,
          search: '',
          area: '',
        });
        setSellers(response.sellers);
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };
    getSellers();
  }, []);
  return (
    <Page
      className={classes.root}
      title="Ventas FH Engel | Agregar Usuario"
    >
      <Container maxWidth={false}>
        <Header />
        <UserCreateForm sellers={sellers} />
      </Container>
    </Page>
  );
}

export default withAuth(CustomerCreateView, SUPER_ADMIN);
