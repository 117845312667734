import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
} from '@mui/material';
import { Redirect } from 'react-router';

import useStyles from './styles';
import { putProductById } from '../../../requests/api/product';

function ProductEditForm({
  className,
  product,
  ...rest
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [submitSuccess, setSubmitSuccess] = useState(false);

  if (submitSuccess) {
    return <Redirect to="/administracion/productos" />;
  }

  return (
    <Formik
      initialValues={{
        description: product.description || '',
        name: product.name || '',
        brand: product.brand || '',
        content: product.content || '',
        category: product.category || '',
        subCategory: product.subCategory || '',
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string(),
      })}
      onSubmit={async (values, {
        setErrors,
        setStatus,
        setSubmitting,
      }) => {
        try {
          // Make API request
          const data = {
            sapId: product.sap_id,
            active: product.active,
            name: values.name,
            description: values.description,
            brand: values.brand,
            category: values.category,
            subCategory: values.subCategory,
            content: values.content,
          };
          const response = await putProductById(data);

          if (response.success) {
            setSubmitSuccess(true);
            setStatus({ success: true });
            setSubmitting(false);
            enqueueSnackbar(response.message, {
              variant: 'success',
            });
          } else {
            setStatus({ success: false });
            setErrors({ submit: response.message });
            enqueueSnackbar(response.message, {
              variant: 'warning',
              action: <Button href="/administracion/productos">Volver atrás</Button>,
            });
          }
        } catch (error) {
          setStatus({ success: false });
          setErrors({ submit: error.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        values,
      }) => (
        <form
          className={clsx(classes.root, className)}
          onSubmit={handleSubmit}
          {...rest}
        >
          <Card>
            <CardContent>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Descripción"
                    name="description"
                    value={values.description}
                    variant="outlined"
                    disabled={true}
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label="Nombre"
                    name="name"
                    value={values.name}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Marca"
                    name="brand"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.brand}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Categoría"
                    name="category"
                    onBlur={handleBlur}
                    onChange={handleChange}

                    defaultValue={values.category}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Subcategoría"
                    name="subCategory"
                    onBlur={handleBlur}
                    onChange={handleChange}

                    defaultValue={values.subCategory}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    type="number"
                    label="Contenido"
                    name="content"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.content}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  md={6}
                  xs={2}
                >
                  <Box mt={1}>
                    <Button
                      variant="contained"
                      color="secondary"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Guardar
                    </Button>
                  </Box>
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={2}
                >
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </form>
      )}
    </Formik>
  );
}

ProductEditForm.propTypes = {
  className: PropTypes.string,
  product: PropTypes.object,
  subcategories: PropTypes.array,
};

export default ProductEditForm;
