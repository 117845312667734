const SET_PRODUCTS_FILTERS = '@account/set-products-filters';
const REFRESH = '@account/refresh';

const setProductsFilters = (productsFilters) => (dispatch) => dispatch({
  type: SET_PRODUCTS_FILTERS,
  payload: {
    productsFilters,
  },
});

const refresh = () => (dispatch) => dispatch({
  type: REFRESH,
});

export default {
  setProductsFilters,
  SET_PRODUCTS_FILTERS,
  refresh,
  REFRESH,
};
