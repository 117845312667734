const SET_SELLERS_FILTERS = '@account/set-sellers-filters';
const REFRESH = '@account/refresh';

const setSellersFilters = (sellersFilters) => (dispatch) => dispatch({
  type: SET_SELLERS_FILTERS,
  payload: {
    sellersFilters,
  },
});

const refresh = () => (dispatch) => dispatch({
  type: REFRESH,
});

export default {
  setSellersFilters,
  SET_SELLERS_FILTERS,
  refresh,
  REFRESH,
};
