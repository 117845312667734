/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';

import {
  Card,
  Grid,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TableBody,
  TextField,
  InputAdornment,
  SvgIcon,
} from '@mui/material';
import MapIcon from '@mui/icons-material/Map';
import SearchIcon from '@mui/icons-material/Search';
import useStyles from './styles';

function ClientDirections({ client }) {
  const classes = useStyles();
  const [search, setSearch] = useState('');
  const [clientDirections, setClientDirections] = useState([]);

  useEffect(() => {
    setClientDirections(client.clientDirections || []);
  }, [client]);

  const handleQueryChange = (query) => {
    setSearch(query);
    const filteredClientDirections = client.clientDirections.filter((e) => e.address.toLowerCase().includes(query.toLowerCase()));
    setClientDirections(filteredClientDirections);
  };

  if (Object.keys(client).length === 0) {
    return null;
  }

  return (
    <Card className={classes.card}>
      <Grid
        container
        className={classes.cardHeaderDiv}
        justify="space-between"
        alignItems="center"
      >
        <Grid item>
          <Grid container alignItems="center" className={classes.cardTitleDiv}>
            <MapIcon className={classes.shoppingBagIcon} />
            <Typography className={classes.cardTitle}>Direcciones</Typography>
          </Grid>
        </Grid>
        <Grid item className={classes.queryGrid}>
          <TextField
            className={classes.queryField}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon fontSize="small" color="action">
                    <SearchIcon />
                  </SvgIcon>
                </InputAdornment>
              ),
            }}
            onChange={(e) => handleQueryChange(e.target.value)}
            value={search}
            placeholder="Buscar direcciones"
            variant="outlined"
            size="small"
          />
        </Grid>
      </Grid>
      <PerfectScrollbar>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} className={classes.scrollableTable}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Código dirección</TableCell>
                  <TableCell>Dirección</TableCell>
                  <TableCell>Comuna</TableCell>
                  <TableCell>Region</TableCell>
                  <TableCell>Ciudad</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {clientDirections.map((clientDirection) => (
                  <TableRow hover key={clientDirection.id}>
                    <TableCell>
                      <Typography color="inherit" variant="h6">
                        {clientDirection.sapId}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography color="inherit" variant="h6">
                        {clientDirection.address}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography color="inherit" variant="h6">
                        {clientDirection.commune.name}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography color="inherit" variant="h6">
                        {clientDirection.region.name}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography color="inherit" variant="h6">
                        {clientDirection.city.name}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </PerfectScrollbar>
    </Card>
  );
}

ClientDirections.propTypes = {
  className: PropTypes.string,
  client: PropTypes.object.isRequired,
};

ClientDirections.defaultProps = {
  client: {},
};

export default ClientDirections;
