import produce from 'immer';
import ordersFiltersActions from '../actions/ordersActions';

const initialState = {
  ordersFilters: {},
};

const ordersFiltersReducer = (state = initialState, action) => {
  switch (action.type) {
    case ordersFiltersActions.SET_ORDERS_FILTERS: {
      const { ordersFilters } = action.payload;

      return produce(state, (draft) => {
        draft.ordersFilters = ordersFilters;
      });
    }

    default: {
      return state;
    }
  }
};

export default ordersFiltersReducer;
