const SET_ORDERS_FILTERS = '@account/set-orders-filters';
const REFRESH = '@account/refresh';

const setOrdersFilters = (ordersFilters) => (dispatch) => dispatch({
  type: SET_ORDERS_FILTERS,
  payload: {
    ordersFilters,
  },
});

const refresh = () => (dispatch) => dispatch({
  type: REFRESH,
});

export default {
  setOrdersFilters,
  SET_ORDERS_FILTERS,
  refresh,
  REFRESH,
};
