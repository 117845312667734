import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    '& .MuiTableCell-head': {
      fontWeight: 700,
    },
  },
  action: {
    marginBottom: theme.spacing(1),
    '& + &': {
      marginLeft: theme.spacing(1),
    },
  },
  actionIcon: {
    marginRight: theme.spacing(1),
  },
  queryField: {
    width: 500,
  },
  bulkOperations: {
    position: 'relative',
  },
  bulkActions: {
    paddingLeft: 4,
    paddingRight: 4,
    marginTop: 6,
    position: 'absolute',
    width: '100%',
    zIndex: 2,
    backgroundColor: theme.palette.background.default,
  },
  bulkAction: {
    marginLeft: theme.spacing(2),
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1),
  },
  shoppingBagIcon: {
    fontSize: '33px',
    marginRight: '8px',
    alignItems: 'center',
    color: theme.palette.secondary.main,
  },
  headerTitle: {
    fontSize: '24px',
    fontWeight: 700,
    fontFamily: theme.typography.Inter.fontFamily,
    color: theme.palette.text.primary,
  },
}));

export default useStyles;
