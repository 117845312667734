import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  MenuItem,
} from '@mui/material';
import { Redirect } from 'react-router';

import { postRegister } from '../../../../requests/api/auth';
import handleApiResponse from '../../../../utils/handleApiResponse';
import { ADMIN, SELLER } from '../../../../constants/userRoles';

import useStyles from './styles';

function UserCreateForm({
  className, stores, sellers, ...rest
}) {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [showAutocomplete, setShowAutocomplete] = useState(false);
  const [selectSeller, setSelectSeller] = useState(null);

  if (submitSuccess) {
    return <Redirect to="/administracion/users" />;
  }

  return (
    <Formik
      initialValues={{
        email: '',
        name: '',
        role: '',
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().max(255).required('Debe ingresar un nombre'),
        email: Yup.string()
          .email('Email inválido')
          .required('Debe ingresar email'),
        role: Yup.string().required('Debe seleccionar un rol'),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        if (values.role === SELLER && !selectSeller) {
          enqueueSnackbar('Debe seleccionar un vendedor', {
            variant: 'error',
          });
          return;
        }

        try {
          // Make API request
          const data = {
            name: values.name,
            email: values.email,
            role: values.role,
            sellerId: selectSeller ? selectSeller.id : 0,
          };

          const response = await postRegister(data);

          handleApiResponse(enqueueSnackbar, response, true);
          setSubmitSuccess(true);
          setStatus({ success: true });
          setSubmitting(false);
        } catch (error) {
          handleApiResponse(
            enqueueSnackbar,
            error,
            false,
            'error',
            <Button href="/administracion/users">Volver a usuarios</Button>,
          );
          setStatus({ success: false });
          setErrors({ submit: error.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form
          className={clsx(classes.rootCreateForm, className)}
          onSubmit={handleSubmit}
          {...rest}
        >
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    helperText={touched.name && errors.name}
                    label="Nombre"
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.name}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    label="Email"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.email}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Rol"
                    name="role"
                    onChange={(e) => {
                      handleChange(e);
                      if (e.target.value === SELLER) {
                        setShowAutocomplete(true);
                      } else {
                        setShowAutocomplete(false);
                      }
                    }}
                    select
                    value={values.role}
                    variant="outlined"
                    required
                  >
                    <MenuItem value={ADMIN}>ADMINISTRADOR</MenuItem>
                    <MenuItem value={SELLER}>VENDEDOR</MenuItem>
                  </TextField>
                </Grid>
                {showAutocomplete && (
                  <Grid item md={6} xs={12}>
                    <Autocomplete
                      value={selectSeller}
                      onChange={(event, newValue) => setSelectSeller(newValue)}
                      options={sellers}
                      getOptionLabel={(option) => `${option.sapId} - ${option.name}`
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Vendedor"
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                )}
              </Grid>
              <Box mt={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Guardar
                </Button>
              </Box>
            </CardContent>
          </Card>
        </form>
      )}
    </Formik>
  );
}

UserCreateForm.propTypes = {
  className: PropTypes.string,
  stores: PropTypes.array,
  sellers: PropTypes.array,
};

export default UserCreateForm;
