import { useSelector } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import { ADMIN, SUPER_ADMIN } from '../../constants/userRoles';

function AuthGuardExternal({ children }) {
  const account = useSelector((state) => state.account);

  if (!account.user) {
    return <Redirect to="/login" />;
  }
  if ([ADMIN, SUPER_ADMIN].includes(account.user.role)) {
    return <Redirect to="/administracion/users" />;
  }
  return children;
}

AuthGuardExternal.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthGuardExternal;
