const SET_CLIENTS_FILTERS = '@account/set-clients-filters';
const REFRESH = '@account/refresh';

const setClientsFilters = (clientsFilters) => (dispatch) => dispatch({
  type: SET_CLIENTS_FILTERS,
  payload: {
    clientsFilters,
  },
});

const refresh = () => (dispatch) => dispatch({
  type: REFRESH,
});

export default {
  setClientsFilters,
  SET_CLIENTS_FILTERS,
  refresh,
  REFRESH,
};
