import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import accountReducer from './accountReducer';
import productsReducer from './productsReducer';
import clientsReducer from './clientsReducer';
import ordersReducer from './ordersReducer';
import sellersReducer from './sellersReducer';

const rootReducer = combineReducers({
  account: accountReducer,
  products: productsReducer,
  clients: clientsReducer,
  orders: ordersReducer,
  sellers: sellersReducer,
  form: formReducer,
});

export default rootReducer;
