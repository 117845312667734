import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import PropTypes from 'prop-types';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import moment from 'moment';
import pesoChileno from '../../../utils/moneyFormat';
import useStyles from './styles';

const ProgressChart = ({ seller }) => {
  const classes = useStyles();

  if (Object.keys(seller).length === 0) {
    return null;
  }

  const { monthsObjectivesAndTotalSell } = seller;
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();
  const objectives = [];
  const months = [];
  const invoices = [];

  for (let i = 2; i >= 0; i -= 1) {
    const date = new Date(currentYear, currentMonth - i, 1);
    const momentMonthAndYear = moment(date).format('MM-YYYY');
    const month = date.toLocaleString('default', { month: 'long' });
    const { totalSell } = monthsObjectivesAndTotalSell[momentMonthAndYear];
    const { objective } = monthsObjectivesAndTotalSell[momentMonthAndYear];
    months.push(month);
    invoices.push(totalSell);
    objectives.push(objective);
  }

  const chartOptions = {
    chart: {
      type: 'column',
    },
    title: {
      text: '',
    },
    xAxis: {
      categories: months,
      gridLineWidth: 0,
      lineWidth: 0,
      tickWidth: 0,
      labels: {
        style: {
          color: '#24335E',
          fontFamily: 'Inter, sans-serif',
          textTransform: 'capitalize', // Primera letra en mayúscula
          fontSize: '16px',
          fontWeight: 700,
        },
      },
    },
    yAxis: {
      stackLabels: {
        enabled: false,
      },
      title: {
        enabled: false,
      },
      labels: {
        enabled: false,
      },
      gridLineWidth: 0,
    },
    legend: {
      verticalAlign: 'top',
      enabled: true,
      itemStyle: {
        fontSize: '16px',
        fontFamily: 'Inter, sans-serif',
        color: '#5F696B',
        fontWeight: 700,
      },
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: false,
        },
      },
      column: {
        borderWidth: 0,
        shadow: false,
      },
    },
    tooltip: {
      backgroundColor: '#D9D9D9',
      borderWidth: 1,
      borderRadius: 5,
      useHTML: true,
      style: {
        fontFamily: 'Inter, sans-serif',
        color: '#24335E',
      },

      formatter() {
        const { series } = this.series.chart;
        const seriesIndex = this.series.index;
        const categoryIndex = this.point.index;
        const label = series[seriesIndex].name;
        const value = series[seriesIndex].yData[categoryIndex];

        if (seriesIndex === 0 && label === 'Meta') {
          return `<div style="text-align: center;"><b style="font-weight: 700">${label}</b><br/>Meta: ${pesoChileno(
            value,
          )}</div>`;
        }
        if (seriesIndex === 1 && label === 'Venta') {
          return `<div style="text-align: center;"><b style="font-weight: 700">${label}</b><br/>Venta: ${pesoChileno(
            value,
          )}</div>`;
        }
        return null;
      },
    },
    series: [
      {
        name: 'Meta',
        data: objectives,
        color: '#62A606',
      },
      {
        name: 'Venta',
        data: invoices,
        color: '#24335E',
      },
    ],
  };

  return (
    <div className={classes.progressChartDiv}>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
      <div className={classes.progressChartTitle}>
        <ArrowRightAltIcon className={classes.arrowIcon} />
        Progreso metas
      </div>
    </div>
  );
};

ProgressChart.propTypes = {
  seller: PropTypes.object.isRequired,
};

export default ProgressChart;
