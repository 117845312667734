import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  IconButton,
  InputAdornment,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  Menu,
  MenuItem,
  Grid,
} from '@mui/material';
import { Search as SearchIcon } from 'react-feather';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ClearIcon from '@mui/icons-material/Clear';
import Autocomplete from '@mui/material/Autocomplete';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import esLocale from 'date-fns/locale/es';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';

import { orderStatusOptions } from '../../../utils/options';
import { ADMIN, SUPER_ADMIN } from '../../../constants/userRoles';

import useStyles from './styles';

import { formatDate } from '../../../utils/date';
import axios from '../../../utils/axios';

function Results({
  className,
  orders,
  page,
  setPage,
  limit,
  setLimit,
  filters,
  setFilters,
  setSendQuery,
  totalOrders,
  sellerRole,
  ...rest
}) {
  const classes = useStyles();
  const [currentOrders, setCurrentOrders] = useState(orders);
  const [openFilter, setOpenFilter] = useState(null);
  const [filtersCounter, setFiltersCounter] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const account = useSelector((state) => state.account);

  useEffect(() => {
    setCurrentOrders(orders);
  }, [orders]);

  useEffect(() => {
    let count = 0;
    count += filters.status.length;
    if (filters.startDate) count += 1;
    if (filters.endDate) count += 1;
    setFiltersCounter(count);
  }, [filters]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handleClick = (event) => {
    setOpenFilter(event.currentTarget);
  };
  const handleClose = () => {
    setOpenFilter(null);
  };

  const handleFilterChange = (value, name) => {
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const handleClearFilters = (event) => {
    event.stopPropagation();
    setFilters({
      search: '',
      status: [],
      startDate: '',
      endDate: '',
    });
    setSendQuery((prev) => !prev);
  };

  const handleDownload = async () => {
    try {
      const response = await axios.get('/api/orders/export', {
        params: {
          page: 0,
          limit: 10000,
          search: filters.search,
          status: filters.status.map((e) => e.value).join(','),
          startDate: filters.startDate,
          endDate: filters.endDate,
          sellerId: account?.user?.seller?.id || 0,
        },
      });

      if (response.data.files.length) {
        response.data.files.forEach((file) => {
          const uri = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${file.file}`;
          const downloadLink = document.createElement('a');
          downloadLink.href = uri;
          downloadLink.download = file.fileName;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        });
      } else {
        enqueueSnackbar('No hay archivos para descargar', { variant: 'warning' });
      }
    } catch (error) {
      enqueueSnackbar('Error al descargar los pedidos', { variant: 'error' });
    }
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        p={2}
        spacing={1}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          spacing={1}
          className={classes.searchGrid}
        >
          <Grid item>
            <TextField
              className={classes.queryField}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              onChange={(e) => handleFilterChange(e.target.value, 'search')}
              placeholder="Razón social o Código"
              value={filters.search}
              variant="outlined"
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={handleClick}
              className={classes.filterButton}
              endIcon={
                <ClearIcon
                  onClick={(event) => handleClearFilters(event)}
                  edge="end"
                  color="inherit"
                  className={classes.clearIcon}
                />
              }
            >
              <div className={classes.filterIcon}>
                <FilterAltIcon />
                Filtros {`(${filtersCounter})`}
              </div>
            </Button>
            <Menu
              anchorEl={openFilter}
              open={Boolean(openFilter)}
              onClose={handleClose}
            >
              <MenuItem>
                <Autocomplete
                  className={classes.filterAutocomplete}
                  multiple
                  options={orderStatusOptions}
                  getOptionLabel={(option) => option.value}
                  isOptionEqualToValue={(option, value) => option.value === value.value
                  }
                  value={filters.status}
                  onChange={(event, value) => handleFilterChange(value, 'status')
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Estado"
                    />
                  )}
                />
              </MenuItem>
              <MenuItem>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={esLocale}
                >
                  <DatePicker
                    inputFormat="DD-MM-yyyy"
                    label="Fecha de inicio"
                    minDate={new Date('2020-02-01')}
                    maxDate={new Date('2030-01-01')}
                    value={filters.startDate}
                    onChange={(value) => handleFilterChange(value || '', 'startDate')
                    }
                    renderInput={(params) => (
                      <TextField {...params} fullWidth variant="outlined" />
                    )}
                  />
                </LocalizationProvider>
              </MenuItem>
              <MenuItem>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={esLocale}
                >
                  <DatePicker
                    inputFormat="DD-MM-yyyy"
                    label="Fecha de termino"
                    minDate={new Date('2020-02-01')}
                    maxDate={new Date('2030-01-01')}
                    value={filters.endDate}
                    onChange={(value) => handleFilterChange(value || '', 'endDate')
                    }
                    renderInput={(params) => (
                      <TextField {...params} fullWidth variant="outlined" />
                    )}
                  />
                </LocalizationProvider>
              </MenuItem>
            </Menu>
          </Grid>
          <Grid item>
            <Button
              color="secondary"
              variant="contained"
              className={classes.searchButton}
              onClick={() => setSendQuery((prev) => !prev)}
            >
              Buscar
            </Button>
          </Grid>
        </Grid>
        <Grid item>
          <Button color="secondary" variant="contained" onClick={handleDownload}>
            Descargar
          </Button>
        </Grid>
      </Grid>

      <PerfectScrollbar>
        <Box minWidth={700}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Código</TableCell>
                <TableCell>Razón social cliente</TableCell>
                <TableCell>Dirección</TableCell>
                <TableCell>Estado</TableCell>
                <TableCell>Fecha entrega</TableCell>
                <TableCell align="right">Ver más</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentOrders.map((order) => (
                <TableRow hover key={order.id}>
                  <TableCell>
                    <Typography color="inherit" variant="h6">
                      {order.sap_id}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color="inherit" variant="h6">
                      {order.client.name}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color="inherit" variant="h6">
                      {order.clientDirection.address}
                      {', '}
                      {order.clientDirection.commune.name}
                      {', '}
                      {order.clientDirection.city.name}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color="inherit" variant="h6">
                      {order.status}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color="inherit" variant="h6">
                      {formatDate(order.date)}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <IconButton
                      component={RouterLink}
                      to={[ADMIN, SUPER_ADMIN].includes(sellerRole) ? `/administracion/pedidos/${order.id}/detalle` : `/vendedor/pedidos/${order.id}/detalle`}
                      size="large"
                    >
                      <SvgIcon fontSize="small">
                        <MoreHorizIcon />
                      </SvgIcon>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={totalOrders}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
        labelRowsPerPage="Filas por página"
      />
    </Card>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  limit: PropTypes.number.isRequired,
  setLimit: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  orders: PropTypes.array,
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
  setSendQuery: PropTypes.func.isRequired,
  totalOrders: PropTypes.number.isRequired,
  sellerRole: PropTypes.string.isRequired,
};

Results.defaultProps = {
  orders: [],
};

export default Results;
