import axios from '../../utils/axios';

const getProductById = async (productId) => {
  const response = await axios.get(`/api/product/${productId}`);

  return response.data;
};

const getProductStocks = async (productId) => {
  const response = await axios.get(`/api/product/${productId}/stocks`);

  return response.data;
};

const getProductsPerPage = async ({
  page, limit, search, active, stock,
}) => {
  const response = await axios.get(
    `/api/products?page=${page}&limit=${limit}&search=${search}&active=${active}&stock=${stock}`,
  );

  return response.data;
};

const putProductById = async ({
  sapId,
  active,
  name,
  brand,
  content,
  category,
  subCategory,
}) => {
  const response = await axios.put('/api/product/edit', {
    sapId,
    active,
    name,
    brand,
    content,
    category,
    subCategory,
  });
  return response.data;
};

export {
  getProductById,
  getProductStocks,
  getProductsPerPage,
  putProductById,
};
