/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Button } from '@mui/material';
import clsx from 'clsx';
import useStyles from './styles';

function ChartButtons({ chart, setChart }) {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={12}>
        <Button
          variant="contained"
          className={clsx(classes.buttons, {
            [classes.buttonsSelected]: chart === 0,
          })}
          onClick={() => setChart(0)}
        >
          Ventas en el tiempo
        </Button>
        <Button
          variant="contained"
          className={clsx(classes.buttons, {
            [classes.buttonsSelected]: chart === 1,
          })}
          onClick={() => setChart(1)}
        >
          Ticket medio
        </Button>
        <Button
          variant="contained"
          className={clsx(classes.buttons, {
            [classes.buttonsSelected]: chart === 2,
          })}
          onClick={() => setChart(2)}
        >
          Venta por grupo/articulos
        </Button>
      </Grid>
    </Grid>
  );
}

ChartButtons.propTypes = {
  chart: PropTypes.number.isRequired,
  setChart: PropTypes.func.isRequired,
};

export default ChartButtons;
