import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    '& .MuiTableCell-head': {
      fontWeight: 700,
    },
  },
  action: {
    marginBottom: theme.spacing(1),
    '& + &': {
      marginLeft: theme.spacing(1),
    },
  },
  actionIcon: {
    marginRight: theme.spacing(1),
  },
  queryField: {
    minWidth: 300,
  },
  bulkOperations: {
    position: 'relative',
  },
  bulkActions: {
    paddingLeft: 4,
    paddingRight: 4,
    marginTop: 6,
    position: 'absolute',
    width: '100%',
    zIndex: 2,
    backgroundColor: theme.palette.background.default,
  },
  bulkAction: {
    marginLeft: theme.spacing(2),
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1),
  },
  shoppingBagIcon: {
    fontSize: '33px',
    marginRight: '8px',
    alignItems: 'center',
    color: theme.palette.secondary.main,
  },
  headerTitle: {
    fontSize: '24px',
    fontWeight: 700,
    fontFamily: theme.typography.Inter.fontFamily,
    color: theme.palette.text.primary,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
    },
  },
  searchButton: {
    minWidth: '200px',
  },
  modalContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    outline: 'none',
  },
  modalContent: {
    backgroundColor: '#fff',
    [theme.breakpoints.up('sm')]: {
      width: '500px',
    },
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 40px 20px 40px',
  },
  modalTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  modalTitle: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 700,
    textAlign: 'left',
    fontSize: 24,
    marginRight: 'auto',
  },
  closeIcon: {
    cursor: 'pointer',
  },
  modalLine: {
    borderTop: '1px solid #222A35',
    margin: '20px 0',
  },
  modalButtonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '30px',
  },
  saveChangesContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '30px',
    background: '#FFFFFF',
    boxShadow: '0px -4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '0px 0px 5px 5px',
  },
  uploadButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
  },
  selectedFileName: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
  deleteButton: {
    minWidth: 'unset',
    padding: 0,
    marginLeft: theme.spacing(1),
    verticalAlign: 'top',
  },
}));

export default useStyles;
