import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  MenuItem,
  Autocomplete,
} from '@mui/material';
import { Redirect } from 'react-router-dom';

import useStyles from './styles';
import { editUserData } from '../../../../requests/api/users';

import handleApiResponse from '../../../../utils/handleApiResponse';
import { SELLER } from '../../../../constants/userRoles';

function UserEditForm({
  className,
  user,
  stores,
  sellers,
  ...rest
}) {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [showAutocomplete, setShowAutocomplete] = useState(
    user.seller !== null || user.seller !== undefined,
  );
  const [selectSeller, setSelectSeller] = useState(user.seller);

  if (submitSuccess) {
    return <Redirect to="/administracion/users" />;
  }

  return (
    <Formik
      initialValues={{
        name: user.name || '',
        role: user.role || '',
        email: user.email || '',
        active: user.active || false,
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().max(255).required('Debe ingresar un nombre'),
        role: Yup.string().required('Debe seleccionar un role'),
        active: Yup.boolean(),
      })}
      onSubmit={async (values, {
        setErrors,
        setStatus,
        setSubmitting,
      }) => {
        try {
          // Make API request
          const data = {
            name: values.name,
            role: values.role,
            active: values.active,
            sellerId: selectSeller ? selectSeller.id : 0,
          };

          const response = await editUserData(user.id, data);

          handleApiResponse(enqueueSnackbar, response, true);
          setSubmitSuccess(true);
          setStatus({ success: true });
          setSubmitting(false);
        } catch (error) {
          handleApiResponse(enqueueSnackbar, error, false, 'error', <Button href="/administracion/users">Volver a usuarios</Button>);
          setStatus({ success: false });
          setErrors({ submit: error.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form
          className={clsx(classes.rootEditForm, className)}
          onSubmit={handleSubmit}
          {...rest}
        >

          <Card>
            <CardContent>
            <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    helperText={touched.name && errors.name}
                    label="Nombre"
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.name}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Email"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    variant="outlined"
                    disabled
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Rol"
                    name="role"
                    onChange={(e) => {
                      handleChange(e);
                      if (e.target.value === SELLER) {
                        setShowAutocomplete(true);
                      } else {
                        setShowAutocomplete(false);
                      }
                    }}
                    select
                    value={values.role}
                    variant="outlined"
                  >
                    <MenuItem
                      value="admin"
                    >
                      ADMIN
                    </MenuItem>
                    <MenuItem value={SELLER}>VENDEDOR</MenuItem>
                  </TextField>
                </Grid>
                {showAutocomplete && (
                  <Grid item md={6} xs={12}>
                    <Autocomplete
                      value={selectSeller}
                      onChange={(event, newValue) => setSelectSeller(newValue)}
                      options={sellers}
                      getOptionLabel={(option) => `${option.sapId} - ${option.name}`
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Vendedor"
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                )}
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                   <TextField
                    fullWidth
                    label="Estado"
                    name="active"
                    onChange={handleChange}
                    select
                    value={values.active}
                    variant="outlined"
                  >
                    <MenuItem
                      value={true}
                    >
                      ACTIVO
                    </MenuItem>
                    <MenuItem
                      value={false}
                    >
                      INACTIVO
                    </MenuItem>
                  </TextField>
                </Grid>
                <Grid item />
              </Grid>
              <Box mt={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Guardar
                </Button>
              </Box>
            </CardContent>
          </Card>
        </form>
      )}
    </Formik>
  );
}

UserEditForm.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object.isRequired,
  stores: PropTypes.array,
  sellers: PropTypes.array,
};

export default UserEditForm;
