import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  IconButton,
  InputAdornment,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  Menu,
  MenuItem,
  Grid,
} from '@mui/material';
import { Search as SearchIcon } from 'react-feather';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ClearIcon from '@mui/icons-material/Clear';
import Autocomplete from '@mui/material/Autocomplete';
import { format } from 'rut.js';
import { ADMIN, SUPER_ADMIN } from '../../../constants/userRoles';
import {
  clientStatusOptions,
  clientPaymentConditionsOptions,
} from '../../../utils/options';
import useStyles from './styles';

function Results({
  className,
  clients,
  page,
  setPage,
  limit,
  setLimit,
  filters,
  setFilters,
  setSendQuery,
  totalClients,
  sellerRole,
  ...rest
}) {
  const classes = useStyles();
  const [currentClients, setCurrentClients] = useState(clients);
  const [openFilter, setOpenFilter] = useState(null);
  const [filtersCounter, setFiltersCounter] = useState(0);

  useEffect(() => {
    setCurrentClients(clients);
  }, [clients]);

  useEffect(() => {
    let count = 0;
    count += filters.status.length;
    count += filters.paymentConditions.length;
    setFiltersCounter(count);
  }, [filters]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handleClick = (event) => {
    setOpenFilter(event.currentTarget);
  };
  const handleClose = () => {
    setOpenFilter(null);
  };

  const handleFilterChange = (value, name) => {
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const handleClearFilters = (event) => {
    event.stopPropagation();
    setFilters({
      search: '',
      status: [],
      paymentConditions: [],
    });
    setSendQuery((prev) => !prev);
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <Grid container alignItems="center" justifyContent="space-between" p={2} spacing={1}>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          spacing={1}
          className={classes.searchGrid}
        >
          <Grid item>
            <TextField
              className={classes.queryField}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              onChange={(e) => handleFilterChange(e.target.value, 'search')}
              placeholder="Rut o Razón Social"
              value={filters.search}
              variant="outlined"
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={handleClick}
              className={classes.filterButton}
              endIcon={
                <ClearIcon
                  onClick={(event) => handleClearFilters(event)}
                  edge="end"
                  color="inherit"
                  className={classes.clearIcon}
                />
              }
            >
              <div className={classes.filterIcon}>
                <FilterAltIcon />
                Filtros {`(${filtersCounter})`}
              </div>
            </Button>
            <Menu
              anchorEl={openFilter}
              open={Boolean(openFilter)}
              onClose={handleClose}
            >
              <MenuItem>
                <Autocomplete
                  className={classes.filterAutocomplete}
                  multiple
                  options={clientStatusOptions}
                  getOptionLabel={(option) => option.value}
                  isOptionEqualToValue={(option, value) => option.value === value.value
                  }
                  value={filters.status}
                  onChange={(event, value) => handleFilterChange(value, 'status')
                  }
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" placeholder="Estado" />
                  )}
                />
              </MenuItem>

              <MenuItem>
                <Autocomplete
                  className={classes.filterAutocomplete}
                  multiple
                  options={clientPaymentConditionsOptions}
                  getOptionLabel={(option) => option.value}
                  isOptionEqualToValue={(option, value) => option.value === value.value
                  }
                  value={filters.paymentConditions}
                  onChange={(event, value) => handleFilterChange(value, 'paymentConditions')
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Condiciones de pago"
                    />
                  )}
                />
              </MenuItem>
            </Menu>
          </Grid>
          <Grid item>
            <Button
              color="secondary"
              variant="contained"
              className={classes.searchButton}
              onClick={() => setSendQuery((prev) => !prev)}
            >
              Buscar
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <PerfectScrollbar>
        <Box minWidth={700}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Rut</TableCell>
                <TableCell>Razón social</TableCell>
                <TableCell>Estado</TableCell>
                <TableCell>Condicion de pago</TableCell>
                <TableCell align="right">Ver más</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentClients.map((client) => (
                <TableRow hover key={client.id}>
                  <TableCell>
                    <Typography color="inherit" variant="h6">
                      {format(client.rut)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color="inherit" variant="h6">
                      {client.name}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color="inherit" variant="h6">
                      {client.status}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color="inherit" variant="h6">
                      {client.paymentCondition.name}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <IconButton
                      component={RouterLink}
                      to={[ADMIN, SUPER_ADMIN].includes(sellerRole) ? `/administracion/clientes/${client.id}/detalle` : `/vendedor/clientes/${client.id}/detalle`}
                      size="large"
                    >
                      <SvgIcon fontSize="small">
                        <MoreHorizIcon />
                      </SvgIcon>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={totalClients}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
        labelRowsPerPage="Filas por página"
      />
    </Card>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  limit: PropTypes.number.isRequired,
  setLimit: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  clients: PropTypes.array,
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
  setSendQuery: PropTypes.func.isRequired,
  totalClients: PropTypes.number.isRequired,
  sellerRole: PropTypes.string.isRequired,
};

Results.defaultProps = {
  clients: [],
};

export default Results;
