import AuthService from '../services/authService';

export const LOGIN_REQUEST = '@account/login-request';
export const LOGIN_SUCCESS = '@account/login-success';
export const LOGIN_FAILURE = '@account/login-failure';
export const SILENT_LOGIN = '@account/silent-login';
export const LOGOUT = '@account/logout';
export const UPDATE_PROFILE = '@account/update-profile';

const login = (email, password) => async (dispatch) => {
  dispatch({ type: LOGIN_REQUEST });
  const response = await AuthService.loginWithEmailAndPassword(email, password);
  const { user } = response;

  const userData = {
    id: user.id,
    email: user.email,
    name: user.name,
    role: user.role,
    seller: user.seller,
    active: user.active,
  };
  dispatch({
    type: LOGIN_SUCCESS,
    payload: {
      userData,
    },
  });
};

export function setUserData(userData) {
  return (dispatch) => dispatch({
    type: SILENT_LOGIN,
    payload: {
      userData,
    },
  });
}

export function logout() {
  return async (dispatch) => {
    AuthService.logoutSession();

    dispatch({
      type: LOGOUT,
    });
  };
}

export function register() {
  return true;
}

export default {
  login,
};
